import { usePostCopilotChatMessage } from "../../../../../api/copilot/chat/post";
import { usePostFile } from "../../../../../api/library/files/post";
import { useCreationStore } from "../../../../../store/creationStore";
import { ICopilotMessage } from "../../../../../utils/copilot-base/ICopilotBase";
import {
  CopilotRole,
  SocialNetworkType,
  SocialNetworkTypeName,
} from "../../../../../utils/enums/Enums";
import { ISingleFIleVM } from "../../../../../utils/interfaces/IAssets";
import { useCreationHelperFunctions } from "../../../hooks/useCreationHelperFunctions";
import { useCreationLibraryHelperFunctions } from "../../Media/hooks/useCreationLibraryHelperFunctions";
import { useUpdateBriefMessages } from "./useUpdateBriefMessages";

export const useBriefHelperFunctions = ({
  setDisable,
  setChatResponse,
  setValue,
  setFiles,
  files,
}: {
  setFiles?: React.Dispatch<React.SetStateAction<File[] | null>>;
  files: File[] | null;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
  setChatResponse: React.Dispatch<React.SetStateAction<string>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { copilotSessionId, briefMessages, setBriefMessages } =
    useCreationStore();
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  const { getSocialNetworkNameByType, getActiveSocialNetworkPost } =
    useCreationHelperFunctions();
  const { mutateAsync: postMessage } = usePostCopilotChatMessage();
  const { updateLoadingMessage } = useUpdateBriefMessages();
  const { mutateAsync: createFile } = usePostFile();

  const sendMessage = async (text: string, storyUpdated: boolean = false) => {
    // if (text.length < 2) return;
    setDisable(true);
    setChatResponse("");
    let uploadedFiles: ISingleFIleVM[] = [];
    if (files && files.length > 0) {
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("files", file);
      });
      await createFile({ files: formData }).then((res) => {
        uploadedFiles = res.data.uploadedFiles;
      });
    }
    setValue("");
    const userMessage: ICopilotMessage = {
      role: CopilotRole.USER,
      content: {
        Type: "UserMessage",
        Message: text,
      },
      files: uploadedFiles.length > 0 ? uploadedFiles : [],
    };
    const aiMessage: ICopilotMessage = {
      role: CopilotRole.ASSISTANT,
      content: undefined,
      files: [],
    };
    let socName: undefined | string = undefined;
    // add additional content for only one social media
    if (
      getActiveSocialNetworkPost()?.text &&
      getActiveSocialNetworkPost()!.text!.length > 0
    ) {
      socName = getSocialNetworkNameByType(
        getActiveSocialNetworkPost()!.socialNetworkType
      );
    }
    if (setFiles) setFiles([]);
    if (uploadedFiles.length > 0) {
      uploadedFiles.map((item) => {
        insertMediaForAllSocialNetworkType(item, false, false, false);
      });
    }
    setBriefMessages([...briefMessages, userMessage, aiMessage]);
    await postMessage({
      message: text,
      fileIds:
        uploadedFiles.length > 0
          ? (uploadedFiles.map((item) => item.id) as number[])
          : undefined,
      sessionId: copilotSessionId!,
      socialNetworkType: socName,
    })
      .then((res) => {
        const toolItem = res.data.data.find(
          (item) => item.role === CopilotRole.TOOL
        );
        const aiMessage = res.data.data.find(
          (item) => item.role === CopilotRole.ASSISTANT
        );
        let tmpMessages = [...useCreationStore.getState().briefMessages];
        if (toolItem && toolItem.files && toolItem.files.length > 0) {
          toolItem.files.map((item) => {
            insertMediaForAllSocialNetworkType(item, false, false, false);
          });
          tmpMessages.splice(tmpMessages.length - 1, 0, {
            files: toolItem.files,
            content: toolItem.content,
            role: CopilotRole.TOOL,
          });
        }
        if (aiMessage) {
          tmpMessages = updateLoadingMessage({
            obj: {
              content: aiMessage.content,
              role: CopilotRole.ASSISTANT,
              files: [],
            },
            tmpMessages: tmpMessages,
          });
        }
        setBriefMessages(tmpMessages);
        setDisable(false);
      })
      .catch((err) => {
        console.log("123", err);
        let tmpMessages = [...useCreationStore.getState().briefMessages];
        tmpMessages = updateLoadingMessage({
          obj: {
            content: err.response.data.data,
            role: CopilotRole.ASSISTANT,
            files: [],
          },
          tmpMessages: tmpMessages,
        });
        setBriefMessages(tmpMessages);
        setDisable(false);
      });
  };

  return { sendMessage };
};
