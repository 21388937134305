import React from "react";
import Head from "./Library/Head";
import { LibraryGridCard } from "../../../Library/LibraryGridCard";
import ImageView from "./Library/ImageView";
import { renderItems } from "../../../Library/functions/renderItems";
import { handleDoubleClick } from "../../../Library/functions/handleDoubleClick";
import { LibrarySkeleton } from "../../../Library/LibrarySkeleton";
import { useCreationLibraryHelperFunctions } from "./hooks/useCreationLibraryHelperFunctions";
import {
  ISingleFoldersAndFilesVM,
} from "../../../../utils/interfaces/IAssets";
import { useCreationHelperFunctions } from "../../hooks/useCreationHelperFunctions";
import { IBreadcrumbsItem } from "../../../../design-system/SBreadcrumbs";
import { EmptyState } from "../../../Library/EmptyState";
import { LibraryDropdown } from "../../../Library/LibraryDropdown";

function LibraryTab({
  folderHistory,
  foldersAndFiles,
  isFetching,
  setFolderHistory,
  setFoldersAndFiles,
  setPage,
  setParentFolder,
  parentFolderId,
  librarySearchValue,
  setOpenModal,
  wrapperRef,
  dropboxRef,
  selectFile,
}: {
  wrapperRef: any;
  folderHistory: IBreadcrumbsItem[];
  setFoldersAndFiles: React.Dispatch<
    React.SetStateAction<ISingleFoldersAndFilesVM[]>
  >;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  setFolderHistory: React.Dispatch<React.SetStateAction<IBreadcrumbsItem[]>>;
  setParentFolder: React.Dispatch<
    React.SetStateAction<{
      id: number | null;
      title: string;
    } | null>
  >;
  isFetching: boolean;
  parentFolderId?: number;
  librarySearchValue?: string;
  dropboxRef: React.RefObject<HTMLInputElement>;
  selectFile: (options: { multiple: boolean }) => void;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { getActiveSocialNetworkPost } = useCreationHelperFunctions();

  const items = renderItems(foldersAndFiles);
  const { insertMediaForAllSocialNetworkType } =
    useCreationLibraryHelperFunctions();
  return (
    <div
      ref={wrapperRef}
      className="mt-[16px] overflow-auto"
      style={{ height: "calc(100% - 96px)" }}
    >
      <Head
        setFolderHistory={setFolderHistory}
        setFoldersAndFiles={setFoldersAndFiles}
        setPage={setPage}
        folderHistory={folderHistory}
        setParentFolder={setParentFolder}
      />
      <div className="mt-[16px] grid gap-[16px] grid-cols-2 lg:grid-cols-3 2xl:grid-cols-6 ">
        {items.map((item) => (
          <>
            {item.cardType === "Folder" && (
              <LibraryGridCard
                className="w-full h-[240px] lg:h-[260px] 2xl:h-[180px]"
                onClick={() => {
                  handleDoubleClick({
                    setPage,
                    setFoldersAndFiles,
                    cardTitle: item.cardTitle,
                    cardType: item.cardType,
                    setFolderHistory,
                    setParentFolder,
                    id: item.id,
                  });
                }}
                cardTitle={item.cardTitle}
                cardType={item.cardType}
                id={item.id}
                setFoldersAndFiles={setFoldersAndFiles}
                setPage={setPage}
              />
            )}
            {(item.cardType === "Image" || item.cardType === "Video") && (
              <ImageView
                isSelected={getActiveSocialNetworkPost()!.files.some(
                  (sItem) => sItem.url === item?.object?.url
                )}
                onClick={() => {
                  if (item.object) {
                    insertMediaForAllSocialNetworkType(
                      item.object,
                      false,
                      true
                    );
                  }
                }}
                source={item.imageSrc as string}
              />
            )}
          </>
        ))}
        {isFetching && (
          <LibrarySkeleton className="!w-[unset] !h-[unset] aspect-square !px-0 !py-0" />
        )}
        {!isFetching && items.length === 0 && (
          <EmptyState
          searchValue={librarySearchValue}
          title="No Assets Found"
          subtitle="Change folders or create one"
          actionButton={
            <LibraryDropdown
              handleFiles={() => selectFile({ multiple: true })}
              handleFolder={() => setOpenModal(true)}
              handleDropbox={() => dropboxRef.current?.click()}
              className="right-1/2 translate-x-1/2"
            />
          }
        />
        )}
      </div>
    </div>
  );
}

export default LibraryTab;
