import SModalBase from "../../design-system/SModalBase";
import {
  faDownload,
  faTrash,
  faX,
} from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../design-system/SButton";
import { getQuery } from "../../hooks/functions/queries";
import { Dispatch, SetStateAction} from "react";
import { UseMutateAsyncFunction } from "react-query";
import { IBaseData } from "../../utils/interfaces/IBase";
import { IFileUpdate, ISingleFIleVM } from "../../utils/interfaces/IAssets";
import { ExpandedRename } from "../creation/Tabs/Media/Library/ExpandedRename";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";

function ExpandedImageModal({
  isOpen,
  onClose,
  source,
  isVideo = false,
  onSave,
  onDownload,
  isEditing,
  onDelete,
  onEdit,
}: {
  isOpen: boolean;
  onClose: () => void;
  onDownload?: (e: any) => void;
  onEdit?: Dispatch<SetStateAction<boolean>>;
  onDelete?: (e: any) => void;
  onSave?: UseMutateAsyncFunction<
    IBaseData<ISingleFIleVM>,
    Error,
    { id: number; data: IFileUpdate },
    unknown
  >;
  isEditing?: boolean;
  source: string;
  isVideo?: boolean;
}) {
  const { personalInfo } = useAccountStore();
  return (
    <SModalBase
      showX={false}
      className="relative"
      dialogPanelClassName="mb-10 max-sm-m:max-w-[calc(100%-60px)] max-sm-m:mr-[35px] max-w-[80dvw] max-h-[80dvh] max-sm-m:max-h-[60dvh]"
      id="expanded-image-modal"
      isOpen={isOpen}
      onClose={onClose}
    >
      {getQuery("name") &&
        getQuery("extension") &&
        getQuery("pathInStorage") &&
        getQuery("id") &&
        onEdit &&
        onSave &&
        personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
          <ExpandedRename
            onEdit={onEdit}
            onSave={onSave}
            isEditing={isEditing}
          />
        )}
      <SButton
        onClick={onClose}
        icon={faX}
        size="sm"
        type="secondaryGray"
        className="absolute left-[calc(100%+6px)] top-0"
      />
      {onDownload && personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
        <SButton
          onClick={onDownload}
          icon={faDownload}
          size="sm"
          type="secondaryGray"
          className="absolute left-[calc(100%+6px)] top-11"
        />
      )}
      {onDelete && personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
        <SButton
          onClick={onDelete}
          icon={faTrash}
          size="sm"
          iconProps={{
            className: "text-error-500",
          }}
          type="secondaryGray"
          className="absolute left-[calc(100%+6px)] top-[88px]"
        />
      )}
      {!isVideo && (
        <img
          src={source}
          alt=""
          className={
            "max-sm-m:max-h-[50dvh] max-h-[70dvh] rounded-xl shadow-md object-cover"
          }
        />
      )}
      {isVideo && (
        <video
          className="max-sm-m:max-h-[50dvh] max-h-[70dvh] rounded-xl shadow-md"
          autoPlay
          controls
        >
          <source src={source} type="video/mp4" />
        </video>
      )}
    </SModalBase>
  );
}

export default ExpandedImageModal;
