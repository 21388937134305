import { Dispatch, SetStateAction, useState, MouseEvent } from "react";
import { useDeleteFoldersAndFiles } from "../../api/library/foldersAndFiles/delete";
import SButton from "../../design-system/SButton";
import { faClose } from "@fortawesome/pro-solid-svg-icons";
import {
  ISingleFIleVM,
  ISingleFoldersAndFilesVM,
} from "../../utils/interfaces/IAssets";
import { useCreationStore } from "../../store/creationStore";
import { useCreationHelperFunctions } from "../creation/hooks/useCreationHelperFunctions";
import { isVideo } from "./hooks/getFileType";
import { toast } from "../../hooks/toast";
import { getSelectedAssets } from "./functions/getSelectedAssets";
import { ConfirmDelete } from "./ConfirmDelete";
import { OrganizationUserRole } from "../../utils/enums/Enums";
import { useAccountStore } from "../../store/accountStore";
import { useGetWorkspaceUsage } from "../../api/workspaces/limits/get";
import { useZipAssets } from "../../hooks/useZipAssets";

export function LibrarySelectNavbar({
  foldersAndFiles,
  setSelectedItems,
  selectedItems,
  className,
  setFoldersAndFiles,
  setPage,
  handleMoveInto,
}: {
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  setSelectedItems: ([{ id, isFolder }]: {
    id: number;
    isFolder: boolean;
  }[]) => void;
  selectedItems: { id: number; isFolder: boolean }[];
  className?: string;
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  handleMoveInto: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    fileIds: number[],
    folderIds: number[]
  ) => void;
}) {
  const { mutateAsync: deleteSelected } = useDeleteFoldersAndFiles({
    setFoldersAndFiles,
    setPage,
    setSelectedItems,
  });
  const [confirmModal, setConfirmModal] = useState(false);
  const { setInAdvanceMedia, reset } = useCreationStore();
  const { handleOpenPost } = useCreationHelperFunctions();
  const handleCreatePost = () => {
    if (!workspaceUsage?.data.canUserAddPosts) {
      toast("You have reached the limit of posts for your workspace.","error");
      return;
    }
    reset();
    let tmpFiles: ISingleFIleVM[] = [];
    foldersAndFiles.map((item) => {
      if (
        selectedItems.some((sItem) => sItem.id === item.id && !sItem.isFolder)
      ) {
        tmpFiles.push({ ...item, id: undefined });
      }
    });
    if (tmpFiles.length === 0) {
      toast("Please select images or video");
      return;
    }
    let isThereImage = false;
    let isThereVideo = false;
    tmpFiles.map((item) => {
      if (isVideo(item.url)) isThereVideo = true;
      else isThereImage = true;
    });
    if (isThereImage && isThereVideo) {
      toast(`You can't select video and image together`);
      return;
    }
    setInAdvanceMedia(tmpFiles);
    handleOpenPost({ shouldReset: false });
  };
  const { personalInfo } = useAccountStore();
  const { data: workspaceUsage } = useGetWorkspaceUsage();
  const { download } = useZipAssets();
  return (
    <>
      {confirmModal &&
        personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
          <ConfirmDelete
            title="Are you sure you want to delete the selected assets?"
            setOpenModal={() => setConfirmModal(false)}
            onConfirm={() => {
              const folderIds = selectedItems
                .filter((item) => item.isFolder)
                .map((item) => item.id);
              const fileIds = selectedItems
                .filter((item) => !item.isFolder)
                .map((item) => item.id);

              deleteSelected({ folderIds, fileIds })
                .then(() => {
                  toast("Folder deleted successfully", "success");
                })
                .catch((e) => {
                  toast(e.response.data.message, "error");
                })
                .finally(() => {
                  setConfirmModal(false);
                });
            }}
            onCancel={() => {
              setConfirmModal(false);
            }}
            openModal={confirmModal}
          />
        )}
      {personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
        <div className={`${className} flex`}>
          <SButton
            onClick={() => {
              setSelectedItems([]);
            }}
            type="secondaryGray"
            className="rounded-r-none bg-gray-50 hover:bg-gray-200 focus:bg-gray-300"
            lIcon={faClose}
          >
            <span>
              {selectedItems.length}{" "}
              <span className={"max-md:hidden"}>Selected</span>
            </span>
          </SButton>
          <SButton
            type="secondaryGray"
            className="border-l-0 rounded-l-none rounded-r-none"
            onClick={handleCreatePost}
          >
            Create Post
          </SButton>
          <SButton
            onClick={(e: any) => {
              const fileIds = selectedItems
                .filter((item) => !item.isFolder)
                .map((item) => item.id);
              const folderIds = selectedItems
                .filter((item) => item.isFolder)
                .map((item) => item.id);
              handleMoveInto(e, fileIds, folderIds);
            }}
            type="secondaryGray"
            className="border-l-0 rounded-l-none rounded-r-none"
          >
            Move
          </SButton>
          <SButton
            onClick={() => {
              const { files, folders } = getSelectedAssets(selectedItems, foldersAndFiles);
              download(files, folders);
            }}
            type="secondaryColor"
            className="border-l-0 rounded-l-none rounded-r-none"
          >
            Download
          </SButton>
          <SButton
            onClick={() => {
              setConfirmModal(true);
            }}
            type="secondaryGray"
            className="border-l-0 rounded-l-none bg-error-400 text-white"
          >
            Delete
          </SButton>
        </div>
      )}
    </>
  );
}
