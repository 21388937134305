import { UseMutateAsyncFunction } from "react-query";
import { toast } from "../../../hooks/toast";
import { IBaseData } from "../../../utils/interfaces/IBase";
import { ISingleFolderVM } from "../../../utils/interfaces/IFolders";
import { ISingleFIleVM } from "../../../utils/interfaces/IAssets";

export interface IConfirmDeleteProps {
  cardType: string;
  deleteFolder: UseMutateAsyncFunction<IBaseData<ISingleFolderVM>, Error, { id: number; }, unknown>;
  deleteFile: UseMutateAsyncFunction<IBaseData<ISingleFIleVM>, Error, { id: number; }, unknown>;
  id: number;
  setOpenConfirmation: (open: boolean) => void;
}

export const confirmDelete = ({
  cardType,
  deleteFolder,
  deleteFile,
  id,
  setOpenConfirmation,
}: IConfirmDeleteProps) => {
  if (cardType === "Folder") {
    deleteFolder({ id })
      .then(() => {
        toast(`Folder deleted successfully`, "success");
      })
      .catch((err) => {
        toast(err.response.data.message, "error");
      });
  } else {
    deleteFile({ id })
      .then(() => {
        toast(`Asset deleted successfully`, "success");
      })
      .catch((err) => {
        toast(err.response.data.message, "error");
      });
  }
  setOpenConfirmation(false);
};