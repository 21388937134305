import JSZip from "jszip";
import {
  ISingleFIleVM,
  ISingleFoldersAndFilesVM,
} from "../utils/interfaces/IAssets";
import { removeExtension } from "../components/Library/functions/removeExtension";
import { useGetFolderById } from "../api/library/folders/get";
import { closeAllToast, toast } from "./toast";

export const useZipAssets = () => {
  const { mutateAsync: getFolderById } = useGetFolderById();
  const zip = new JSZip();

  const download = (
    files?: ISingleFIleVM[],
    folders?: ISingleFoldersAndFilesVM[]
  ) => {
    const filePromises = files
      ? files.map((file) =>
          fetch(file.url)
            .then((response) => response.blob())
            .then((blob) => {
              const fileName =
                removeExtension(file.name || "", file.extension) +
                file.extension;
              zip.file(fileName, blob);
            })
        )
      : [];

    const folderPromises = folders
      ? folders.map(async (folder) => {
          const { data: folderData } = await getFolderById({ id: folder.id });
          const filePromises = folderData.files.map((file) => {
            return fetch(file.url)
              .then((response) => response.blob())
              .then((blob) => {
                const fileName =
                  removeExtension(file.name || "", file.extension) +
                  file.extension;
                zip.folder(folder.name || "Folder")?.file(fileName, blob);
              });
          });
          return Promise.all(filePromises);
        })
      : [];

    toast("Downloading files...", "loading");

    Promise.all([...filePromises, ...folderPromises.flat()]).then(() => {
      zip.generateAsync({ type: "blob" }).then((content) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(content);
        link.download = "library_files.zip";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        closeAllToast();
      });
    });
  };

  return { download };
};
