import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SModalBase from "../../design-system/SModalBase";
import { ISingleFoldersAndFilesVM } from "../../utils/interfaces/IAssets";
import {
  faChevronRight,
  faFolderOpen,
} from "@fortawesome/pro-regular-svg-icons";
import SBreadcrumbs, {
  IBreadcrumbsItem,
} from "../../design-system/SBreadcrumbs";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import SButton from "../../design-system/SButton";
import { MouseEvent } from "react";
import { faFolderPlus } from "@fortawesome/pro-solid-svg-icons";

export const LibraryAssetRelocation = ({
  moveIntoModal,
  setMoveIntoModal,
  foldersAndFiles,
  handleMoveOut,
  folderHistory,
  setFolderHistory,
  setFoldersAndFiles,
  setPage,
  setParentFolder,
  defaultFolderId,
  setOpenModal,
}: {
  moveIntoModal: boolean;
  setMoveIntoModal: (value: boolean) => void;
  foldersAndFiles: ISingleFoldersAndFilesVM[];
  handleMoveOut: (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    fileIds: number[],
    folderIds: number[],
    parentId?: number | null
  ) => void;
  defaultFolderId?: number;
  folderHistory: IBreadcrumbsItem[];
  setFolderHistory: Dispatch<SetStateAction<IBreadcrumbsItem[]>>;
  setFoldersAndFiles: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
  setPage: Dispatch<SetStateAction<number>>;
  setParentFolder: (
    value: null | {
      id: number | null;
      title: string;
    }
  ) => void;
}) => {
  const folderIds: number[] = JSON.parse(
    new URLSearchParams(window.location.search).get("folderIds") || "[]"
  );
  const fileIds: number[] = JSON.parse(
    new URLSearchParams(window.location.search).get("fileIds") || "[]"
  );
  const [initialId, setInitialId] = useState<number | null | undefined>(null);
  useEffect(() => {
    setInitialId(folderHistory[folderHistory.length - 1].id);
  }, []);
  return (
    <SModalBase
      dialogPanelClassName="w-[550px]"
      isOpen={moveIntoModal}
      onClose={setMoveIntoModal}
    >
      <div className={`w-full overflow-x-auto relative py-3`}>
        <SBreadcrumbs
          needHome={false}
          needGoBack={folderHistory.length >= 2}
          onGoBack={() => {
            const prevItem = folderHistory[folderHistory.length - 2];
            folderHistory.length > 2 &&
              setFolderHistory((prev) => prev.slice(0, -1));
            setFoldersAndFiles([]);
            setPage(1);
            setParentFolder({
              id: prevItem?.id ? prevItem?.id : null,
              title: prevItem.title,
            });
          }}
          pages={folderHistory}
        />
      </div>
      <div onDoubleClick={(e) => e.stopPropagation()} className="w-full h-full">
        <div className="h-[250px] overflow-auto bg-gray-100 rounded-lg mt-3 px-3 py-2">
          {foldersAndFiles
            ?.filter((item) => {
              return !item.extension && !folderIds.includes(item.id);
            })
            .map((item) => {
              return (
                <div
                  onClick={(e) => {
                    setFoldersAndFiles([]);
                    setPage(1);
                    setParentFolder({ id: item.id, title: item.name });
                    setFolderHistory((prevCards) => {
                      return [
                        ...prevCards,
                        {
                          id: item.id,
                          title: item.name,
                          type: "transparent",
                          callback: () => {
                            setFoldersAndFiles([]);
                            setPage(1);
                            setParentFolder({ id: item.id, title: item.name });
                          },
                        },
                      ];
                    });
                  }}
                  className="w-full hover:bg-gray-200 rounded-lg flex cursor-pointer items-center gap-3 p-2"
                >
                  <FontAwesomeIcon
                    size="sm"
                    className="text-gray-600"
                    icon={faFolderOpen}
                  />
                  <h1 className="text-gray-600 text-sm-m">{item.name}</h1>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="xs"
                    className="text-gray-600 ml-auto"
                  />
                </div>
              );
            })}
        </div>
        <div className="w-full mt-3 flex items-center">
          <SButton
            onClick={() => {
              setOpenModal(true);
            }}
            icon={faFolderPlus}
            type="secondaryGray"
          />
          <div className="w-full flex justify-end gap-3 items-center">
            <SButton
              onClick={() => setMoveIntoModal(false)}
              children="Cancel"
              type="secondaryGray"
            />
            <SButton
              onClick={(e: any) => {
                const id =
                  folderHistory.length === 1
                    ? defaultFolderId
                    : folderHistory[folderHistory.length - 1].id;

                handleMoveOut(e, fileIds, folderIds, id);
                setMoveIntoModal(false);
              }}
              disabled={initialId === folderHistory[folderHistory.length - 1].id}
              children="Place"
            />
          </div>
        </div>
      </div>
    </SModalBase>
  );
};
