import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import {
  faBackward,
  faHome,
  faLeft,
  faSlash,
  faSlashForward,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";
import SButton from "../SButton";

export interface IBreadcrumbsItem {
  id?: number | null;
  title: string;
  callback: string | (() => void);
  type: "transparent" | "background" | "color";
}

interface ISBreadcrumbs {
  needHome?: boolean;
  pages: IBreadcrumbsItem[];
  icon?: "arrow" | "slash";
  needGoBack?: boolean;
  onGoBack?: () => void;
}

const valueByType = (
  type: "transparent" | "background" | "color",
  arr: string[],
) => {
  switch (type) {
    case "transparent":
      return arr[0];
      break;
    case "background":
      return arr[1];
      break;
    case "color":
      return arr[2];
      break;
  }
};

function SBreadcrumbs({
  needHome = true,
  needGoBack = true,
  pages,
  icon = "arrow",
  onGoBack,
}: ISBreadcrumbs) {
  const navigate = useNavigate();
  return (
    <div className="flex gap-[12px] items-center">
      {needHome && (
        <>
          <FontAwesomeIcon
            onClick={() => navigate("/")}
            icon={faHome}
            className="text-gray-500  cursor-pointer mb-[2px]"
          />

          <FontAwesomeIcon
            onClick={() => navigate("/")}
            icon={icon === "arrow" ? faChevronRight : faSlashForward}
            fontSize={"12px"}
            className="text-gray-300"
          />
        </>
      )}
      <SButton
        type="secondaryGray"
        icon={needGoBack ? faLeft : faHome}
        size="sm"
        disabled={!needGoBack}
        onClick={onGoBack}
      />
      {pages.map((item, i) => (
        <>
          <p
            onClick={() => {
              if (typeof item.callback === "function") {
                if (pages.length - 1 !== i) {
                  item.callback();
                }
              } else {
                // TODO: ak unda chavamato navigacia
                alert("navigaciaa chasamatebeli");
              }
            }}
            className={`${item.type === "transparent" ? "text-sm-m" : "text-sm-sb"} cursor-pointer
            ${item.type === "background" && "px-[8px] py-[4px] bg-gray-50 rounded-[6px]"}
            ${valueByType(item.type, ["text-gray-600", "text-gray-700", "text-brand-700"])}`}
          >
            {item.title}
          </p>
          {i !== pages.length - 1 && (
            <FontAwesomeIcon
              icon={icon === "arrow" ? faChevronRight : faSlashForward}
              fontSize={"12px"}
              className="text-gray-300"
            />
          )}
        </>
      ))}
    </div>
  );
}

export default SBreadcrumbs;
