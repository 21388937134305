import { Dispatch, MouseEventHandler, SetStateAction } from "react";
import LibraryView from "../components/Library/LibraryView";
import { ISingleFoldersAndFilesVM } from "../utils/interfaces/IAssets";

export type CardType = "Folder" | "Image" | "PDF" | "Video" | "Word" | "Text";

export interface ILibraryProps {
  id: number;
  cardType: CardType;
  extension?: string;
  pathInStorage?: string;
  object?: ISingleFoldersAndFilesVM;
  cardTitle: string;
  imageSrc?: string;
  isSelected?: boolean;
  rawTitle?: string;
  className?: string;
  filesCount?: number;
  isRoot?: boolean;
  fileSize?: number;
  hasOptions?: boolean;
  parentFolderId?: number | null;
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  handleMoveInto?: (e: any, fileIds: number[], folderIds: number[]) => void;
  setPage?: Dispatch<SetStateAction<number>>;
  onClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onDoubleClick?: MouseEventHandler<HTMLDivElement> | undefined;
  onDelete?: () => void;
  onEdit?: () => void;
}

function Library() {
  return (
    <div>
      {/* <Sidebar /> */}
      <LibraryView />
    </div>
  );
}

export default Library;
