import { ILibraryProps } from "../../pages/Library";
//@ts-ignore
import FolderIcon from "../../assets/icons/folder-icon.svg";
//@ts-ignore
import WordFile from "../../assets/icons/word-file.svg";
//@ts-ignore
import PDFIcon from "../../assets/icons/pdf.svg";
//@ts-ignore
import TextIcon from "../../assets/icons/text.svg";
import { useGridCardResize } from "./hooks/useGridCardResize";
import { LibraryCreateFolderModal } from "./LibraryCreateFolderModal";
import { ConfirmDelete } from "./ConfirmDelete";
import { useGridCardActions } from "./hooks/useGridCardActions";
import { useRef } from "react";
import { useHandleOutsideClick } from "../../hooks/useHandleOutsideClick";
import { LibraryOptionsButton } from "./LibraryOptionsButton";
import { toast } from "../../hooks/toast";
import { confirmDelete } from "./functions/confirmDelete";

export function LibraryGridCard({
  className,
  cardType,
  cardTitle,
  imageSrc,
  isSelected = false,
  filesCount,
  onClick,
  onDoubleClick,
  hasOptions = true,
  setFoldersAndFiles,
  setPage,
  id,
  extension,
  pathInStorage,
  rawTitle,
  fileSize,
  handleMoveInto,
  parentFolderId,
  isRoot,
}: ILibraryProps) {
  const { sizeRef, size } = useGridCardResize();
  const {
    open,
    setOpen,
    openRename,
    setOpenRename,
    openConfirmation,
    setOpenConfirmation,
    value,
    setValue,
    handleRename,
    handleDelete,
    updateFolder,
    deleteFolder,
    updateFile,
    deleteFile,
    handleMoveOut,
    handleDownload,
    handleFolderDownload
  } = useGridCardActions({ setFoldersAndFiles, setPage, rawTitle });

  const dropdownRef = useRef<HTMLDivElement>(null);
  useHandleOutsideClick({ ref: dropdownRef, callBack: () => setOpen(false) });

  return (
    <div
      ref={sizeRef}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
      className={`${className} overflow-visible h-[275px] max-md:h-[fit-content] [&_button]:hover:inline cursor-pointer aspect-square flex flex-col w-[50%] md2:w-full rounded-[8px] border max-md:w-full ${isSelected ? "border-green-200 bg-green-25" : "border-gray-200 bg-gray-25"} overflow-hidden`}
    >
      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() =>
            confirmDelete({
              cardType,
              deleteFolder,
              deleteFile,
              id,
              setOpenConfirmation,
            })
          }
        />
      )}
      {openRename && (
        <LibraryCreateFolderModal
          action="Rename"
          type={cardType === "Folder" ? "Folder" : "File"}
          openModal={openRename}
          setOpenModal={() => setOpenRename(false)}
          name={value}
          setName={(e) => setValue(e.target!.value)}
          onSave={() => {
            if (cardType === "Folder") {
              updateFolder({ folderName: value, id: id })
                .then(() => {
                  toast(`Folder renamed successfully`, "success");
                })
                .catch(() => {
                  toast(`Folder rename failed`, "error");
                });
            } else {
              updateFile({
                id: id,
                data: {
                  name: value,
                  extension: extension,
                  pathInStorage: pathInStorage,
                },
              })
                .then(() => {
                  toast(`Asset renamed successfully`, "success");
                })
                .catch(() => {
                  toast(`Asset rename failed`, "error");
                });
            }
            setOpenRename(false);
            setValue("");
          }}
        />
      )}
      <div
        className={`w-full select-none h-full flex justify-center items-center ${size && size <= 200 ? "pt-[26px]" : "pt-[40px]"}`}
      >
        {cardType === "Folder" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={FolderIcon}
            alt="folder"
          />
        )}
        {cardType === "Image" && imageSrc && (
          <img
            src={imageSrc}
            alt={cardTitle}
            className={`rounded-[8px] ${size && size <= 200 ? "w-[50%]" : "w-[70%] object-cover md:h-[120px] lg2:max-w-3/4"}`}
          />
        )}
        {cardType === "Video" && (
          <video
            muted
            className={`${size && size <= 200 ? "w-[50%]" : "w-[70%] md:max-h-[120px] lg2:max-w-3/4 rounded-[8px]"}`}
          >
            <source src={imageSrc} type="video/mp4" />
          </video>
        )}
        {cardType === "PDF" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={PDFIcon}
            alt="PDF FIle"
          />
        )}
        {cardType === "Word" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={WordFile}
            alt="word document"
          />
        )}
        {cardType === "Text" && (
          <img
            className={`${size && size <= 200 ? "w-[30%]" : "md:max-w-[35%] lg2:max-w-[100px] xl:max-w-[135px] drop-shadow-xl"}`}
            src={TextIcon}
            alt="text file"
          />
        )}
      </div>
      <div
        className={`flex relative h-[136px] flex-row justify-between items-center ${size && size <= 200 ? "py-0" : "py-[18px]"} px-[18px]`}
      >
        <div
          className={`flex flex-col items-start gap-[4px] ${size && size <= 200 ? "px-0" : "px-[20px]"} `}
        >
          <p
            className={`font-semibold break-all ${size && size <= 200 ? `${size && size <= 105 ? "text-xs-sb" : "text-sm-sb"}` : "text-md-sb"} `}
          >
            {size && size > 320 && cardTitle}
            {size && size <= 320 && cardTitle.substring(0, 15)}
            {extension}
          </p>
          <div
            className={`${size && size <= 160 ? "hidden" : "flex"} items-center gap-2 text-xs-m text-gray-500`}
          >
            {cardType === "Folder" && <p>{filesCount} Files</p>}
            {cardType !== "Folder" && (
              <p>{fileSize && (fileSize / (1024 * 1024)).toFixed(2)} MB</p>
            )}
          </div>
        </div>
        <LibraryOptionsButton
          open={open}
          id={id}
          isRoot={isRoot}
          cardType={cardType}
          cardTitle={cardTitle}
          imageSrc={imageSrc}
          setOpen={setOpen}
          hasOptions={hasOptions}
          dropdownRef={dropdownRef}
          handleDelete={handleDelete}
          handleRename={handleRename}
          handleDownload={handleDownload}
          handleFolderDownload={handleFolderDownload}
          handleMoveOut={(e) =>
            handleMoveOut(
              e,
              cardType !== "Folder" ? [id] : [],
              cardType === "Folder" ? [id] : [],
              parentFolderId
            )
          }
          handleMoveInto={(e) =>
            handleMoveInto &&
            handleMoveInto(
              e,
              cardType !== "Folder" ? [id] : [],
              cardType === "Folder" ? [id] : []
            )
          }
        />
      </div>
    </div>
  );
}
