import { Dispatch, MouseEvent, SetStateAction, useState } from "react";
import { usePutFolder } from "../../../api/library/folders/put";
import { useDeleteFolder } from "../../../api/library/folders/delete";
import { usePutFile } from "../../../api/library/files/put";
import { useDeleteFile } from "../../../api/library/files/delete";
import { ISingleFoldersAndFilesVM } from "../../../utils/interfaces/IAssets";
import { usePutFoldersAndFiles } from "../../../api/library/foldersAndFiles/put";
import { useSearchParams } from "react-router-dom";
import { useGetFolderById } from "../../../api/library/folders/get";
import { useZipAssets } from "../../../hooks/useZipAssets";

export const useGridCardActions = ({
  setFoldersAndFiles,
  setPage,
  rawTitle,
}: {
  setFoldersAndFiles?: Dispatch<SetStateAction<ISingleFoldersAndFilesVM[]>>;
  setPage?: Dispatch<SetStateAction<number>>;
  rawTitle?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [openRename, setOpenRename] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [value, setValue] = useState(rawTitle ? rawTitle : "");
  const [moveIntoModal, setMoveIntoModal] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const {mutateAsync: getFolderById} = useGetFolderById();
  const { download } = useZipAssets();

  const { mutateAsync: updateFolder } = usePutFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFolder } = useDeleteFolder({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: updateFile } = usePutFile({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: deleteFile } = useDeleteFile({
    setFoldersAndFiles,
    setPage,
  });

  const { mutateAsync: moveAsset } = usePutFoldersAndFiles({
    setPage,
    setFoldersAndFiles,
  });

  const handleRename = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenRename(true);
  };

  const handleDelete = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpenConfirmation(true);
  };

  const handleMoveOut = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    fileIds: number[],
    folderIds: number[],
    parentId?: number | null,
  ) => {
    e.stopPropagation();
    moveAsset({ folderIds, fileIds, parentFolderId: parentId ? parentId : null });
  };

  const handleMoveInto = (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    fileIds: number[],
    folderIds: number[],
  ) => {
    e.stopPropagation();
    setSearchParams({ fileIds: JSON.stringify(fileIds), folderIds: JSON.stringify(folderIds) })
    setMoveIntoModal(true);
  };

  const handleDownload = async (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    url: string,
    name: string
  ) => {
    e.stopPropagation();
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleFolderDownload = async (
    e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>,
    folderId: number,
  ) => {
    e.stopPropagation();
    const { data } = await getFolderById({ id: folderId });
    download(data.files, []);
  };

  return {
    open,
    setOpen,
    openRename,
    setOpenRename,
    openConfirmation,
    setOpenConfirmation,
    value,
    setValue,
    handleRename,
    handleDelete,
    updateFolder,
    deleteFolder,
    updateFile,
    deleteFile,
    handleDownload,
    handleMoveOut,
    handleMoveInto,
    moveIntoModal,
    setMoveIntoModal,
    handleFolderDownload,
  };
};
