import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import {
  faArrowLeftToBracket,
  faArrowRightToBracket,
  faDownload,
  faPencil,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import { Dispatch, SetStateAction, MouseEvent, useState } from "react";
import SSmallMenu from "../../design-system/SSmallMenu";
import { useLocation } from "react-router-dom";
import { useAccountStore } from "../../store/accountStore";
import { OrganizationUserRole } from "../../utils/enums/Enums";

export interface ILibraryOptionsButton {
  id: number;
  hasOptions: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  open: boolean;
  dropdownRef: any;
  handleRename: (e: any) => void;
  handleMoveInto: (e: any) => void;
  handleMoveOut: (e: any) => void;
  handleDelete: (e: any) => void;
  handleDownload: (e: any, url: string, name: string) => void;
  handleFolderDownload: (e: any, folderId: number) => void;
  cardTitle: string;
  imageSrc?: string;
  cardType: string;
  isRoot?: boolean;
}

export const LibraryOptionsButton = ({
  hasOptions,
  setOpen,
  open,
  dropdownRef,
  handleRename,
  handleMoveInto,
  handleMoveOut,
  handleDelete,
  handleDownload,
  cardType,
  imageSrc,
  cardTitle,
  isRoot,
  id,
  handleFolderDownload,
}: ILibraryOptionsButton) => {
  let buttonId = `library-menu-opener-${Math.random()}`;
  const location = useLocation();
  const [yPosition, setYPosition] = useState("top-[calc(100%-24px)]");
  const handleClick = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    setOpen((value) => !value);
    const dropdownRect = dropdownRef.current.getBoundingClientRect();
    const spaceBelow = window.innerHeight - dropdownRect.bottom;
    const spaceAbove = dropdownRect.top;
    if (spaceBelow < dropdownRect.height + 175 && spaceAbove > spaceBelow) {
      setYPosition("top-[calc(100%-24px)] translate-y-[-100%]");
    } else {
      setYPosition("top-[calc(100%-24px)]");
    }
  };
  const { personalInfo } = useAccountStore();
  return (
    <>
      {hasOptions && personalInfo?.userRole !== OrganizationUserRole.VIEWER && (
        <button
          id={buttonId}
          ref={dropdownRef}
          onDoubleClick={(e) => e.stopPropagation()}
          onClick={(e) => handleClick(e)}
          className={`${open ? "inline" : "hidden"} bg-transparent relative border-0 p-[8px]`}
        >
          <FontAwesomeIcon
            icon={faEllipsisVertical}
            className="text-[16px] text-gray-600"
          />
          {open && cardType !== "Folder" && (
            <SSmallMenu
              isOpen={open}
              setIsOpen={(p) => setOpen(p)}
              excludedId={buttonId}
              className={`right-[16px] z-10 left-[unset] ${yPosition} whitespace-nowrap`}
              data={[
                [
                  {
                    text: "Download",
                    icon: faDownload,
                    handleClick: (e) =>
                      handleDownload(e, imageSrc ? imageSrc : "", cardTitle),
                  },
                  {
                    text: "Rename",
                    icon: faPencil,
                    handleClick: (e) => handleRename(e),
                  },
                  {
                    text: "Move Into",
                    icon: faArrowRightToBracket,
                    handleClick: handleMoveInto,
                  },
                  ...(!isRoot
                    ? [
                        {
                          text: "Move Outside",
                          icon: faArrowLeftToBracket,
                          handleClick: handleMoveOut,
                        },
                      ]
                    : []),
                  {
                    text: "Delete",
                    icon: faTrash,
                    handleClick: (e) => handleDelete(e),
                    color: "text-error-600",
                  },
                ],
              ]}
            />
          )}
          {open && cardType === "Folder" && (
            <SSmallMenu
              isOpen={open}
              setIsOpen={(p) => setOpen(p)}
              excludedId={buttonId}
              className="right-[16px] z-20 left-[unset] top-[calc(100%-24px)] whitespace-nowrap"
              data={[
                [
                  {
                    text: "Download",
                    icon: faDownload,
                    handleClick: (e) => handleFolderDownload(e, id),
                  },
                  {
                    text: "Rename",
                    icon: faPencil,
                    handleClick: (e) => handleRename(e),
                  },
                  ...(location.pathname !== "/content"
                    ? [
                        {
                          text: "Move Into",
                          icon: faArrowRightToBracket,
                          handleClick: handleMoveInto,
                        },
                      ]
                    : []),
                  ...(location.pathname !== "/content" && !isRoot
                    ? [
                        {
                          text: "Move Out",
                          icon: faArrowLeftToBracket,
                          handleClick: handleMoveOut,
                        },
                      ]
                    : []),
                  {
                    text: "Delete",
                    icon: faTrash,
                    handleClick: (e) => handleDelete(e),
                    color: "text-error-600",
                  },
                ],
              ]}
            />
          )}
        </button>
      )}
    </>
  );
};
