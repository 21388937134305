import { faCheck, faPencil, faX } from "@fortawesome/pro-regular-svg-icons";
import SButton from "../../../../../design-system/SButton";
import { getQuery } from "../../../../../hooks/functions/queries";
import { toast } from "../../../../../hooks/toast";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { UseMutateAsyncFunction } from "react-query";
import { IBaseData } from "../../../../../utils/interfaces/IBase";
import {
  IFileUpdate,
  ISingleFIleVM,
} from "../../../../../utils/interfaces/IAssets";

export interface IExpandedRenameProps {
  onEdit?: Dispatch<SetStateAction<boolean>>;
  onSave?: UseMutateAsyncFunction<
    IBaseData<ISingleFIleVM>,
    Error,
    { id: number; data: IFileUpdate },
    unknown
  >;
  isEditing?: boolean;
}

export const ExpandedRename = ({
  isEditing,
  onEdit,
  onSave,
}: IExpandedRenameProps) => {
  const editValueRef = useRef<HTMLInputElement>(null);
  const [editValue, setEditValue] = useState(getQuery("name") || "");
  useEffect(() => {
    if (isEditing) {
      editValueRef.current?.focus();
      editValueRef.current?.select();
    }
  }, [isEditing]);

  return (
    <div className="flex max-sm:flex-col max-sm:items-start items-center gap-2 absolute top-[calc(100%+6px)] left-0">
      <input
        ref={editValueRef}
        value={editValue || ""}
        onChange={(e) => setEditValue && setEditValue(e.target.value)}
        disabled={!isEditing}
        className={`text-lg-m max-sm:text-sm text-gray-700 bg-white px-3 py-2 rounded-xl`}
      />
      <div className="flex items-center gap-2">
        {!isEditing && (
          <SButton
            onClick={() => onEdit && onEdit(true)}
            icon={faPencil}
            size="md"
            type="secondaryGray"
          />
        )}
        {isEditing && (
          <>
            <SButton
              onClick={() => {
                setEditValue && setEditValue(getQuery("name") || "");
                onEdit && onEdit(false);
              }}
              icon={faX}
              size="md"
              type="secondaryGray"
            />
            <SButton
              onClick={() => {
                onSave &&
                  onSave({
                    id: parseInt(getQuery("id") || "1"),
                    data: {
                      name: editValue,
                      extension: getQuery("extension") || "",
                      pathInStorage: getQuery("pathInStorage") || "",
                    },
                  })
                    .then(() => {
                      toast("File renamed successfully!", "success");
                      onEdit && onEdit(false);
                    })
                    .catch((err) => toast(err.message, "error"));
              }}
              icon={faCheck}
              size="md"
              type="secondaryGray"
            />
          </>
        )}
      </div>
    </div>
  );
};
