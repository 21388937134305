import PageHeading from "../PageHeading";
import { LibraryGridCard } from "./LibraryGridCard";
import { renderItems } from "./functions/renderItems";
import { useFolderHistory } from "./hooks/useFolderHistory";
import { LibrarySkeleton } from "./LibrarySkeleton";
import { useSelectableItems } from "../../hooks/useSelectableItems";
import { LibrarySelectNavbar } from "./LibrarySelectNavbar";
import { handleDoubleClick } from "./functions/handleDoubleClick";
import { useEffect, useState } from "react";
import { useUploadAssets } from "./hooks/useUploadAssets";
import { LibraryCreateFolderModal } from "./LibraryCreateFolderModal";
import { LibraryLoadingFilesModal } from "./LibraryLoadingFilesModal";
import { LibraryLoadingFile } from "./LibraryLoadingFile";
import { LibraryDropdown } from "./LibraryDropdown";
// @ts-ignore
import DropboxChooser from "react-dropbox-chooser";
import { useLibraryDropbox } from "./hooks/useLibraryDropbox";
import { EmptyState } from "./EmptyState";
import SBreadcrumbs from "../../design-system/SBreadcrumbs";
import { toast } from "../../hooks/toast";
import ExpandedImageModal from "../utils/ExpandedImageModal";
import { useGridCardActions } from "./hooks/useGridCardActions";
import { useSearchParams } from "react-router-dom";
import { getQuery } from "../../hooks/functions/queries";
import { ConfirmDelete } from "./ConfirmDelete";
import { LibraryAssetRelocation } from "./LibraryAssetRelocation";
import { faX } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function LibraryView() {
  //const [selected, setSelected] = useState(0);

  const [searchValue, setSearchValue] = useState("");
  const {
    folderHistory,
    foldersAndFiles,
    setPage,
    setFoldersAndFiles,
    setFolderHistory,
    setParentFolder,
    isFetching,
    parentFolderId,
    defaultFolderId,
  } = useFolderHistory({ wrapperRef: null, searchValue });
  const [clickTimeout, setClickTimeout] = useState<number | null>(null);
  const items = renderItems(foldersAndFiles);

  const { selectFile, createFolder, isPending, files, createFile, setFiles } =
    useUploadAssets({
      setFoldersAndFiles,
      setPage,
      parentFolderId,
    });

  const { selectedItems, handleSelectItem, setSelectedItems } =
    useSelectableItems(items);

  const [openModal, setOpenModal] = useState(false);
  const [name, setName] = useState("");

  const { dropboxRef, onDropboxSelectSubmited, dropboxFiles, setDropboxFiles } =
    useLibraryDropbox({
      createFile,
      parentFolderId,
      isPending,
      setFoldersAndFiles,
      setPage,
    });

  useEffect(() => {
    setSelectedItems([]);
  }, [foldersAndFiles, setSelectedItems]);

  // const tabs: ITabItem[] = [
  //   {
  //     index: 0,
  //     icon: faTableCellsLarge,
  //   },
  //   {
  //     index: 1,
  //     icon: faBars,
  //   },
  // ];
  const {
    handleDownload,
    updateFile,
    deleteFile,
    setOpenConfirmation,
    openConfirmation,
    handleDelete,
    setMoveIntoModal,
    moveIntoModal,
    handleMoveInto,
    handleMoveOut,
  } = useGridCardActions({ setFoldersAndFiles, setPage });
  const [expandedImage, setExpandedImage] = useState<string | null>(null);
  let [searchParams, setSearchParams] = useSearchParams();
  const [editExpandedAsset, setEditExpandedAsset] = useState(false);
  return (
    <div className="pt-[24px] pb-[48px] w-full">
      {openConfirmation && (
        <ConfirmDelete
          title="Are you sure you want to delete this asset?"
          setOpenModal={() => setOpenConfirmation(false)}
          openModal={openConfirmation}
          onCancel={() => setOpenConfirmation(false)}
          onConfirm={() => {
            deleteFile({ id: parseInt(getQuery("id") || "") })
              .then(() => {
                toast(`Asset deleted successfully`, "success");
              })
              .catch((err) => {
                toast(err.response.data.message, "error");
              });
            setOpenConfirmation(false);
            setExpandedImage(null);
            setSearchParams("");
          }}
        />
      )}
      {moveIntoModal && (
        <LibraryAssetRelocation
          setMoveIntoModal={setMoveIntoModal}
          setOpenModal={setOpenModal}
          moveIntoModal={moveIntoModal}
          foldersAndFiles={foldersAndFiles}
          handleMoveOut={handleMoveOut}
          folderHistory={folderHistory}
          setFolderHistory={setFolderHistory}
          setFoldersAndFiles={setFoldersAndFiles}
          setPage={setPage}
          setParentFolder={setParentFolder}
          defaultFolderId={defaultFolderId}
        />
      )}
      <LibraryLoadingFilesModal
        setDropboxFiles={setDropboxFiles}
        setFiles={setFiles}
        isPending={isPending}
      >
        {files?.map((file) => {
          return (
            <LibraryLoadingFile
              url={URL.createObjectURL(file)}
              type={file.type}
              name={file.name}
              size={file.size}
              isPending={isPending}
            />
          );
        })}
        {dropboxFiles?.map((file: any) => {
          return (
            <LibraryLoadingFile
              url={file.thumbnailLink}
              type="image/jpeg"
              icon={file.icon}
              name={file.name}
              size={file.bytes}
              isPending={isPending}
            />
          );
        })}
      </LibraryLoadingFilesModal>
      {expandedImage && (
        <ExpandedImageModal
          isOpen={!!expandedImage}
          onDownload={(e) => handleDownload(e, expandedImage, "")}
          onClose={() => {
            setSearchParams("");
            setExpandedImage(null);
            setEditExpandedAsset(false);
          }}
          onEdit={setEditExpandedAsset}
          onDelete={handleDelete}
          isEditing={editExpandedAsset}
          source={expandedImage}
          onSave={updateFile}
          isVideo={
            expandedImage.includes(".mp4") ||
            expandedImage.includes(".mov") ||
            expandedImage.includes(".avi")
          }
        />
      )}
      {openModal && (
        <LibraryCreateFolderModal
          openModal={openModal}
          action="Create"
          type="Folder"
          setOpenModal={() => setOpenModal(false)}
          name={name}
          setName={(e) => setName(e.target!.value)}
          onSave={() => {
            createFolder({ name: name, parentFolderId })
              .then(() => {
                setOpenModal(false);
                setName("");
                toast("Folder created successfully", "success");
              })
              .catch((err) => {
                toast(err.response.data.message, "error");
              });
          }}
        />
      )}
      <PageHeading
        title="Brand Library"
        subtitle={"Upload and manage your marketing materials."}
        withInput={true}
        inputProps={{
          placeholder: "Search...",
          value: searchValue,
          onChange: (e) => setSearchValue(e.target.value),
          rightAdditionalContent: <FontAwesomeIcon className={`${searchValue.length > 0 ? "block" : "hidden"}`} onClick={() => setSearchValue("")} size='xs' icon={faX} />,
        }}
        rightAdditionalContent={
          <>
            {/* <STertiaryTabs
              items={tabs}
              selected={selected}
              setSelected={setSelected}
              /> */}
            <LibraryDropdown
              handleFiles={() => selectFile({ multiple: true })}
              handleFolder={() => setOpenModal(true)}
              handleDropbox={() => dropboxRef.current?.click()}
            />
            <DropboxChooser
              appKey="js3i72s02ssirnl"
              success={(files: any) => {
                onDropboxSelectSubmited(files);
              }}
              extensions={[
                ".jpg",
                ".jpeg",
                ".png",
                ".mp4",
                ".mov",
                ".avi",
                ".wmv",
                ".flv",
                ".webm",
                ".pdf",
                ".docx",
                ".doc",
                ".xlsx",
                ".xls",
                ".txt",
              ]}
              multiselect={true}
            >
              <input ref={dropboxRef} className="hidden" />
            </DropboxChooser>
          </>
        }
      />
      <div className="flex items-center justify-between my-[20px] max-md:flex-col max-md:items-start max-md:gap-[12px]">
        <SBreadcrumbs
          needHome={false}
          needGoBack={folderHistory.length >= 2}
          onGoBack={() => {
            const prevItem = folderHistory[folderHistory.length - 2];
            folderHistory.length > 2 &&
              setFolderHistory((prev) => prev.slice(0, -1));
            setFoldersAndFiles([]);
            setPage(1);
            setParentFolder({
              id: prevItem?.id ? prevItem?.id : null,
              title: prevItem.title,
            });
          }}
          pages={folderHistory}
        />
        <LibrarySelectNavbar
          foldersAndFiles={foldersAndFiles}
          className={`${selectedItems.length > 0 ? "opacity-100" : "opacity-0 pointer-events-none"} transition-all`}
          setSelectedItems={setSelectedItems}
          selectedItems={selectedItems}
          setFoldersAndFiles={setFoldersAndFiles}
          handleMoveInto={handleMoveInto}
        />
      </div>
      <div className="">
        {items.length === 0 && !isFetching && (
          <EmptyState
            searchValue={searchValue}
            title="No Assets Found"
            subtitle="Change folders or create one"
            actionButton={
              <LibraryDropdown
                handleFiles={() => selectFile({ multiple: true })}
                handleFolder={() => setOpenModal(true)}
                handleDropbox={() => dropboxRef.current?.click()}
                className="right-1/2 translate-x-1/2"
              />
            }
          />
        )}
        <div className="grid grid-cols-1 md2:grid-cols-[repeat(auto-fill,_minmax(275px,_1fr))] gap-4 max-md:pb-[50px]">
          {items.length > 0 &&
            items.map((item, index: number) => (
              <LibraryGridCard
                handleMoveInto={handleMoveInto}
                rawTitle={item.rawTitle}
                parentFolderId={
                  folderHistory.length > 2
                    ? folderHistory[folderHistory.length - 2].id
                    : defaultFolderId
                }
                isRoot={folderHistory.length === 1}
                setPage={setPage}
                setFoldersAndFiles={setFoldersAndFiles}
                isSelected={selectedItems.some(
                  (selectedItem) =>
                    selectedItem.id === item.id &&
                    selectedItem.isFolder === !item.extension
                )}
                filesCount={item.filesCount}
                onClick={() =>
                  handleSelectItem && handleSelectItem(item.id, !item.extension)
                }
                onDoubleClick={() => {
                  handleDoubleClick({
                    setSearchParams,
                    pathInStorage: item.pathInStorage,
                    setPage,
                    setFoldersAndFiles,
                    clickTimeout,
                    setClickTimeout,
                    setSelectedItems,
                    cardTitle: item.cardTitle,
                    cardType: item.cardType,
                    setFolderHistory,
                    setParentFolder,
                    id: item.id,
                    extension: item.extension,
                    setExpandedImage: setExpandedImage,
                    imageSrc: item.imageSrc,
                  });
                }}
                key={index}
                {...item}
              />
            ))}
          {isFetching && (
            <>
              <LibrarySkeleton />
              <LibrarySkeleton />
              <LibrarySkeleton />
              <LibrarySkeleton />
            </>
          )}
        </div>
        {/* {selected === 0 ? (
            <div className="flex flex-row flex-wrap gap-[16px]">
              {items.map((item, index: number) => (
                <LibraryGridCard
                  setFolderHistory={setFolderHistory}
                  setParentFolder={setParentFolder} 
                key={index} 
                  {...item} 
                />
              ))}
            </div>
          ) : (
            <LibraryList
              items={items}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              selectedItems={selectedItems}
              handleSelectItem={handleSelectItem}
            />
          )} */}
      </div>
    </div>
  );
}
