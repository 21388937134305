import {
  faCamera,
  faCheck,
  faMicrophone,
  faStop,
} from "@fortawesome/pro-regular-svg-icons";
import { faPaperPlaneAlt } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import SButton from "../../../../design-system/SButton";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { toast } from "react-toastify";
import {
  SelectFileOptions,
  useUploadFiles,
} from "../../../../hooks/useUploadFiles";
import { Tooltip } from "react-tooltip";
import { useCreationStore } from "../../../../store/creationStore";
import VoiceRecorder from "./VoiceRecorder";

function Textarea({
  files,
  value,
  setValue,
  sendMessage,
  disable,
  selectFile,
}: {
  files: File[] | null;
  selectFile: (options: SelectFileOptions) => void;
  disable: boolean;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  sendMessage: (t: string) => void;
}) {
  const { transcript, resetTranscript, listening } = useSpeechRecognition();
  const { suggestedPrompts, setSuggestedPrompts } = useCreationStore();
  const [lastState, setLastState] = useState("");
  useEffect(() => {
    if (listening) setValue(transcript);
  }, [transcript]);

  useEffect(() => {
    if (!disable) {
      document.getElementById("create-brand-chat-textarea")?.focus();
    }
  }, [disable]);

  const handleSend = () => {
    if (value && value.trim().length > 0) {
      if (files && files.length > 5) {
        toast.error("You can't upload more than 5 image");
        return;
      }
      setSuggestedPrompts([]);
      sendMessage(value);
    } else if (files && files.length > 0) {
      sendMessage(value);
      setSuggestedPrompts([]);
    }
  };

  let mtCondition = (files && files.length > 0) || suggestedPrompts.length > 0;

  return (
    <div
      className={`${mtCondition ? "mt-[12px]" : "mt-[32px]"} relative max-h-[104px] h-full`}
    >
      <textarea
        id="create-brand-chat-textarea"
        disabled={disable}
        placeholder="Send a message..."
        className="h-full focus-visible:outline-[0] focus:border-gray-300 pr-[160px] focus:ring-0  w-full text-md bg-base-white border border-gray-300 shadow-xs rounded-[8px] resize-none"
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
          setValue(e.target.value);
          // handleChange(e);
        }}
        value={listening ? lastState + " " + value : value}
        onKeyDown={(e) => {
          if (e.shiftKey && e.key === "Enter") return;
          if (e.code === "Enter") {
            e.preventDefault();
            handleSend();
          }
        }}
      />
      <div className="absolute bottom-[12px] right-[12px] flex gap-[6px]">
        {/* selectFile({ multiple: true })
         */}
        <div
          onClick={() => selectFile({ multiple: true, accept: "images" })}
          className="w-[40px] h-[40px] rounded-[8px] flex justify-center items-center bg-base-white cursor-pointer text-gray-500"
        >
          <FontAwesomeIcon icon={faCamera} />
        </div>
        <VoiceRecorder
          value={value}
          setValue={setValue}
          setLastState={setLastState}
          lastState={lastState}
          listening={listening}
          transcript={transcript}
          resetTranscript={resetTranscript}
        />
        {files && files.length > 5 && (
          <Tooltip id="brief-send-message-button" place="top">
            You can't upload more than 5 image
          </Tooltip>
        )}
        <SButton
          type={"secondaryColor"}
          data-tooltip-id="brief-send-message-button"
          disabled={disable || !!(files && files?.length > 5)}
          size="md"
          style={{
            border: "none",
            background: "none",
          }}
          onClick={() => {
            handleSend();
          }}
        >
          <FontAwesomeIcon icon={faPaperPlaneAlt} />
        </SButton>
      </div>
    </div>
  );
}

export default Textarea;
